import React, {useState, useEffect} from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import { useForm, Controller } from "react-hook-form";
import { useStyles } from "./styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from "clsx";
import {useFlags} from "launchdarkly-react-client-sdk";

export const signInSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Required"),
  password: Yup.string()
    .required("Required")
});

export const signInSchemaSSO = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Required")
});

const SigninForm = props => {
  const classes = useStyles();
  const { ssoLogin } = useFlags();
  const [isDisabled, setIsDisabled] = useState(ssoLogin ? props.email?.length == 0 : false);
  const { handleSubmit, control, setValue, formState: { errors, isSubmitting } } = useForm({
    resolver: yupResolver(ssoLogin ? signInSchemaSSO : signInSchema)
  });

  const emailChange = (e) => {
    setValue('email', e.target.value);
    setIsDisabled(ssoLogin && (
      isSubmitting || !e.target.checkValidity()
    ));
  }

  useEffect(() => {
    if (props.email?.length > 0) {
      setValue('email', props.email);
    }
  }, []);


  return (
    <form
      className={classes.form}
      onSubmit={handleSubmit(async (values) => await props.onSubmit(values))}
      noValidate
    >
      <Controller
        name="email"
        control={control}
        render={({ field }) => <TextField
          {...field}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          type="email"
          id="email"
          label="Email Address"
          autoComplete="off"
          autoFocus
          error={!!errors.email}
          onChange={emailChange}
          helperText={errors.email && errors.email.message}
        />}
      />

      {ssoLogin == false && (
        <Controller
        name="password"
        control={control}
        render={({ field }) => <TextField
          {...field}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Password"
          type="password"
          id="password"
          autoComplete="off"
          error={!!errors.password}
          helperText={errors.password && errors.password.message}
        />}
        />
      )}


      <div className={classes.submitWrapper}>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={isDisabled}
          className={clsx(`signin-button`)}
        >
          {ssoLogin ? "Next" : "Sign In"}
        </Button>
        {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
      <Grid container>
        <Grid item xs>
          <Link component={RouterLink} to="/login/reset" variant="body2">
            Forgot password?
          </Link>
        </Grid>
      </Grid>
    </form>
  );
};

export default SigninForm;
