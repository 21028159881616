import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  form: {
    width: "100%",
    marginTop: theme.spacing(1)
  },
  submitWrapper: {
    marginBottom: theme.spacing(1),
    position: "relative"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  emailLabel: {
    marginTop: theme.spacing(5)
  },
  labelGrid: {
    justifyContent: "space-between",
    alignItems: "baseline"
  },
  password: {
    marginTop: theme.spacing(.5),
  },
  button: {
    backgroundColor: theme.palette.common.white,
    transform: "rotate(180deg)",
    "& .MuiButton-startIcon": {
      marginLeft: "0px",
      marginRight: "0px"
    },
    "& .MuiSvgIcon-root": {
      fill: theme.palette.common.black 
    }
  }
}));
