import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import { useForm, Controller } from "react-hook-form";
import { useStyles } from "./styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from "clsx";
import { IconButton, Typography, Tooltip } from "@material-ui/core";
import { ForwardSharp } from "@material-ui/icons";

export const signInSchema = Yup.object().shape({
  password: Yup.string()
    .required("Required")
});

const SigninFormPassword = props => {
  const classes = useStyles();
  const { handleSubmit, control, formState: { errors, isSubmitting } } = useForm({
    resolver: yupResolver(signInSchema)
  });

  const submitClick = async values => {
    const { password } = values;
    const loginDetails = {
        email: props.email,
        password: password
    };
    props.onSubmit(loginDetails);
  };

  const goBack = () => {
    props.onClear();
  }

  return (
    <form
      className={classes.form}
      onSubmit={handleSubmit(async (values) => await submitClick(values))}
      noValidate
    >
      <Grid container className={classes.labelGrid}>
        <Grid item>
          <Typography component="h2"	name="email" className={classes.emailLabel}>{props.email}</Typography>
        </Grid>
        <Grid item>
          <Tooltip title="Edit Email Address" arrow placement="top-start"> 
            <Button onClick={goBack} className={classes.button} startIcon={<ForwardSharp />} />
          </Tooltip>
        </Grid>
      </Grid>

      <Controller
        name="password"
        control={control}
        render={({ field }) => <TextField
          {...field}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Password"
          type="password"
          id="password"
          autoComplete="off"
          error={!!errors.password}
          helperText={errors.password && errors.password.message}
          className={classes.password}
        />}
      />


      <div className={classes.submitWrapper}>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={isSubmitting}
          className={clsx(`signin-button`)}
        >
          Sign In
        </Button>
        {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
      <Grid container>
        <Grid item xs>
          <Link component={RouterLink} to="/login/reset" variant="body2">
            Forgot password?
          </Link>
        </Grid>
      </Grid>
    </form>
  );
};

export default SigninFormPassword;
